/**
 * 使用combineReducers合并多个reducer函数
 */
import { combineReducers } from "redux";
import * as root from "./reducer";

let rootReducer = combineReducers({
    loginReducer: root.loginReducer,
    pageReducer: root.pageReducer
})

export default rootReducer