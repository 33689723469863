import * as type from "../../actions/types"
//初始化状态
const intialState = {
    loginState: {
        loginState: false,
        userName: "远东诚信",
        token: "baas"
    },
    pages: {
        idforPage: 1,
        credentialsPage: 1
    }
};

export const loginReducer = (state = intialState.loginState, action) => {
    switch (action.type) {
        case type.CHANGE_LOGIN_STATE:
            return { ...state, loginState: action.payload, userName: action.userName, token: action.token };
        default:
            return state
    }
}
export const pageReducer = (state = intialState.pages, action) => {
    switch (action.type) {
        case type.CHANGE_IDFOR_PAGE:
            return { ...state, idforPage: action.idforPage };
        case type.CHANGE_CREDENTIALS_PAGE:
            return { ...state, credentialsPage: action.credentialsPage }
        default:
            return state;
    }
}