import React, { Suspense } from 'react';
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
// import Home from "./views/home/home";
// import DataIdfor from "./views/DataIdfor/DataIdfor";
// import DataCollaboration from "./views/dataCollaboration/dataCollaboration";
// import DataHealth from "./views/datahealth/datahealth";
// import Datasupplychain from "./views/dataSupplyChain/dataSupplyChain";
// import Ecologicalcooperation from "./views/ecologicalcooperation/ecologicalcooperation";
// import CooperationBook from "./views/cooperationBook/cooperationBook"
// import Document from "./views/document/document"
// import About from "./views/about/about";
// import Login from "./views/login/login";
// import FindPassword from "./views/findPassword/findPassword"
// import Register from "./views/register/register";
// import RegisterSuccess from "./views/register/RegisterSuccess";
// import ActivationSuccess from "./views/register/activationSuccess";
// import ActivationOverdue from "./views/error/erroroverdue";
// import Bsp from "./views/bsp";
// import AuthRouter from './commponents/AuthRouter/AuthRouter';
// import Error500 from "./views/error/error500";

const Home = React.lazy(() => import("./views/home/home"));
const DataIdfor = React.lazy(() => import("./views/DataIdfor/DataIdfor"));
const DataCollaboration = React.lazy(() => import("./views/dataCollaboration/dataCollaboration"));
const DataHealth = React.lazy(() => import("./views/datahealth/datahealth"));
const Datasupplychain = React.lazy(() => import("./views/dataSupplyChain/dataSupplyChain"));
const Ecologicalcooperation = React.lazy(() => import("./views/ecologicalcooperation/ecologicalcooperation"));
const CooperationBook = React.lazy(() => import("./views/cooperationBook/cooperationBook"));
const Document = React.lazy(() => import("./views/document/document"));
const About = React.lazy(() => import("./views/about/about"));
const Login = React.lazy(() => import("./views/login/login"));
const FindPassword = React.lazy(() => import("./views/findPassword/findPassword"));
const Register = React.lazy(() => import("./views/register/register"));
const RegisterSuccess = React.lazy(() => import("./views/register/RegisterSuccess"));
const ActivationSuccess = React.lazy(() => import("./views/register/activationSuccess"));
const ActivationOverdue = React.lazy(() => import("./views/error/erroroverdue"));
const Bsp = React.lazy(() => import("./views/bsp"));
const AuthRouter = React.lazy(() => import("./commponents/AuthRouter/AuthRouter"));
const Error500 = React.lazy(() => import("./views/error/error500"))
function App(props) {
  window.onbeforeunload = () => {
    localStorage.setItem('store', JSON.stringify(props.state));
  };
  return <HashRouter>
    <Suspense fallback>
      <Switch>
        <Route path="/home" component={Home} />
        <Route path="/dataIdfor" component={DataIdfor} />
        <Route path="/dataCollaboration" component={DataCollaboration} />
        <Route path="/datahealth" component={DataHealth} />
        <Route path="/datasupplychain" component={Datasupplychain} />
        <Route path="/cooperation" component={Ecologicalcooperation} />
        <Route path="/cooperation-book" component={CooperationBook} />
        <Route path="/document" component={Document} />
        <Route path="/about" component={About} />
        <Route path="/login" component={Login} />
        <Route path="/findPassword" component={FindPassword} />
        <Route path="/register" component={Register} />
        <Route path="/registerSuccess/:email" component={RegisterSuccess} />
        <Route path="/activationSuccess" component={ActivationSuccess} />
        <Route path="/activationOverdue" component={ActivationOverdue} />
        <Route path="/error500" component={Error500} />
        <AuthRouter path="/bsp" component={Bsp} />
        <Redirect to="/home" />
      </Switch>

    </Suspense>
  </HashRouter>
}
/**
 *  接收状态，返回一个对象，与组件属性合并
 * @param {*} state 
 */
const mapStateToProps = (state) => {
  return {
    state
  }
}

export default connect(mapStateToProps)(App)